<template>
    <b-container fluid>
        <b-row>
            <b-col sm="12">
              <iq-card className="book-detail" bodyClass="p-0">
                <template v-slot:body>
                  <iframe :src="pdf"  style="width: 100%; height: 90vh;"></iframe>
                </template>
              </iq-card>
            </b-col>
        </b-row>
    </b-container>
    </template>
    <script>
    import { core } from '../../config/pluginInit'
    import { mapGetters } from 'vuex'
    import axios from 'axios'
    export default {
      name: 'Bookpdf',
      mounted () {
        this.loadData()
        core.index()
      },
      computed: {
        ...mapGetters({
          lang: 'Setting/langState'
        })
      },
      watch: {
      },
      methods: {
        loadData(){
            axios.get('https://buku-backend.mysurvey.id/buku/' + this.$route.params.id).then(response => {
                this.pdf = response.data.data.nama_file
            })
        }
      },
      data () {
        return {
          pdf: null
        }
      }
    }
    </script>